// authApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: "https://liveshopping.asia" }), // Your API base URL
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/doLogin', // Your login API endpoint
                method: 'POST',
                body: credentials,
            }),
        }),
    }),
});

export const { useLoginMutation } = authApi;
