import React, { useEffect, useLayoutEffect, useState } from 'react';
import "./login.css";
import { FaUser } from "react-icons/fa";
import { CgKey } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaSpinner } from "react-icons/fa";
import { loginSuccess, loginFailure, setLoading } from '../redux/apiSlice';
import { useLoginMutation } from '../redux/authApi';
import { Helmet } from "react-helmet";

const Login = () => {
    const [showPassWord, setShowPassWord] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [login, { isLoading }] = useLoginMutation();
    const [userData, setUserData] = useState({
        password: "", mobile: ""
    });
    const [errors, setErrors] = useState({
        password: "", mobile: "", loginError: ""
    });

    const handleInputChange = (fieldName, value) => {
        setUserData(prevUserData => ({
            ...prevUserData,
            [fieldName]: value
        }));
    };

    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        if (validateForm()) {
            dispatch(setLoading(true))
            console.log(userData);
            try {
                if (userData.mobile.includes('@')) {
                    const { data } = await login({ ...userData, withEmail: 1 });
                    if (data) {
                        if (data.status === 1) {
                            dispatch(loginSuccess({ ...data.data, brand_id: data.data.id }));
                            setTimeout(() => {
                                dispatch(setLoading(false))
                                navigate("/admin")
                            }, 2000);
                        } else {
                            setErrors({
                                password: "", mobile: "", loginError: data.message
                            })
                        }

                    }
                } else {
                    const { data } = await login({ ...userData, withEmail: 0 });
                    if (data) {
                        if (data.status === 1) {
                            dispatch(loginSuccess({ ...data.data, brand_id: data.data.id }));
                            setTimeout(() => {
                                dispatch(setLoading(false))
                                navigate("/admin")
                            }, 2000);
                        } else {
                            setErrors({
                                password: "", mobile: "", loginError: data.message
                            })
                        }

                    }
                }
                // const { data } = await login(userData);


            } catch (error) {
                dispatch(loginFailure(error.message));
                dispatch(setLoading(false))
                console.log(error);
            }
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Validate mobile number
        if (!userData.mobile.trim()) {
            newErrors.mobile = "Mobile number is required";
            isValid = false;
        }
        // Validate password
        if (!userData.password.trim()) {
            newErrors.password = "Password is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    // const authState = useSelector((state) => state.auth);
    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'yourQueryParam' parameter
    const userName = queryParams.get('username');
    const key = queryParams.get('key');
    const myFun = async () => {
        const { data } = await login({
            password: atob(key), mobile: userName
        });
        if (data) {
            if (data.status === 1) {
                dispatch(loginSuccess({ ...data.data, brand_id: data.data.id }));
                setTimeout(() => {
                    dispatch(setLoading(false))
                    navigate("/admin")
                }, 2000);
            } else {
                setErrors({
                    password: "", mobile: "", loginError: data.message
                })
            }

        }
    }

    useLayoutEffect(() => {
        if (userName && key) {
            try {
                setUserData({
                    password: atob(key),
                    mobile: userName
                });
                myFun()

            } catch (error) {
                console.log(error);
            }
        }
    }, [userName, key])

    return (
        <>
            <Helmet>
                <title>SWIRL | Login</title>
            </Helmet>
            <div className='d-flex'>
                {/* Left Column */}
                <div className='login_page_col_left'>
                    <div className='left_layer'>
                        <div className='top_left_logo p-3 pt-4'>
                            <img alt='logo' src='https://assets-global.website-files.com/63f0e6822ce56e6262917200/645e226dea7561daaabcd74f_Logo.svg' />
                        </div>
                        <div className='bottom_text p-3 text-center mb-5'>
                            <h3 >Live streaming effortlessly bridges the gap between nations, uniting the world in a virtual embrace.</h3>
                            <div className='underline_bottom_text mt-5'></div>
                        </div>
                    </div>
                </div>
                {/* Right Column */}
                <div className='login_page_col_right'>
                    <div className='login_box'>
                        <div className=' card col-6 border-0'>
                            <h2 className='welcome_text'>Welcome back</h2>
                            <p className='text-muted m-0 p-0'>Please log in to your account.</p>
                            {/* Login Form */}
                            <form className='mt-3' onSubmit={handleLogin}>
                                {/* User ID Field */}
                                <label>User ID</label>
                                <div className="input-group input-group">
                                    <span className="input-group-text border-end-0" >
                                        <FaUser color='#09121F' />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control border-start-0"
                                        aria-label="Sizing example input"
                                        aria-describedby="Enter user id"
                                        name='mobile'
                                        placeholder='Enter user id'
                                        value={userData.mobile}
                                        onChange={(e) => handleInputChange("mobile", e.target.value)}
                                    />

                                </div>
                                {errors.mobile && <div className="invalid-feedback d-block text-danger ps-1">{errors.mobile}</div>}
                                {/* Password Field */}
                                <label className='mt-3'>Password</label>
                                <div className="input-group ">
                                    <span className="input-group-text border-end-0">  <CgKey /></span>
                                    <input
                                        type={showPassWord ? "text" : "password"}
                                        className="form-control border-start-0 border-end-0"
                                        aria-label="Enter password"
                                        placeholder='Enter password'
                                        value={userData.password}
                                        name='password'
                                        onChange={(e) => handleInputChange("password", e.target.value)}
                                    />

                                    <span className="input-group-text border-start-0" style={{ cursor: "pointer" }} onClick={() => { setShowPassWord(!showPassWord) }}>
                                        {showPassWord ? <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.5" clip-path="url(#clip0_430_3505)">
                                                <path d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z" fill="#09121F" />
                                                <line x1="19" y1="5" x2="5" y2="19" stroke="#09121F" stroke-width="2" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_430_3505">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg> : <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g opacity="0.5" clipPath="url(#clip0_430_3505)">
                                                <path d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z" fill="#09121F" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_430_3505">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>}


                                    </span>
                                </div>
                                {errors.password && <span className="invalid-feedback text-danger d-block mt-0 ps-1">{errors.password}</span>}
                                {/* Login Button */}
                                <button type="submit" className="btn  w-100 login_btn mt-3" disabled={isLoading}>
                                    {isLoading ? <> <FaSpinner className='spinner' /> Signing In...</> : "Sign In"}
                                </button>
                                {errors.loginError && <div className='ps-3 mt-3' style={{ color: "red", borderRadius: "15px" }}>
                                    {errors.loginError && <div className="invalid-feedback d-block text-danger ps-1">{errors.loginError}</div>}
                                </div>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
