import React, { useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import Companyinfo from '../components/profile/Companyinfo';
import './Pages.css'
import Profileproducts from '../components/profile/Profileproducts';
import AccessToken from '../components/profile/AccessToken';
import { Helmet } from 'react-helmet';
const Profile = () => {
    const [activeTab, setActiveTab] = useState('companyinfo');
    return (
        <div>
            <Helmet>
                <title>SWIRL | Profile</title>
            </Helmet>
            <Container style={{ height: "calc(100vh - 170px)", border: "1px solid #D1D1D2", borderRadius: "5px", overflow: "auto" }} fluid>
                <Row className='h-100 ps-2'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="companyinfo" onSelect={(k) => setActiveTab(k)}>
                        <Row className='h-100 pt-3 pb-3'>
                            <Col md={2} className=" mb-3" style={{ borderRight: "1px solid #D1D1D2" }}>
                                <Nav variant="pills" className="flex-column mt-3 video_settting_tabs">
                                    <Nav.Item>
                                        <Nav.Link className="p-3" eventKey="companyinfo">Company info.</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className="p-3" eventKey="products">Products</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className="p-3" eventKey="accesstoken">Access Token</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Col>
                            <Col md={activeTab === 'accesstoken' ? 5 : 10} className="p-3">
                                <Tab.Content>
                                    <h2 className='m-3'>Profile Settings</h2>
                                    <Tab.Pane eventKey="companyinfo">
                                        <Companyinfo />

                                        <button className='btn btn-dark m-3'>
                                            Save Changes
                                        </button>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="products">
                                        <Profileproducts />

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="accesstoken">
                                        <AccessToken />

                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>

                        </Row>
                    </Tab.Container>
                </Row>
            </Container>
        </div>
    )
}

export default Profile