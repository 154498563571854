import React, { useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import img from '../../assets/images/newdeals.png'
import { FaTrophy, FaFireAlt, FaSpinner } from "react-icons/fa";
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FallingLines } from 'react-loader-spinner';

const ProductUrl = ({
  productLink, setProductLink,
  productName, setProductName,
  productDescription, setProductDescription,
  mrp, setMrp,
  sellingPrice, setSellingPrice,
  productTag, setProductTag,
  productImage, setProductImage,
  productImageName, setProductImageName,
  productImagePreview, setProductImagePreview,
  productImageSize, setProductImageSize,
  brandid, setBrandid,
  setProductSku, productsku,
  productattribute, setProductAttribute,
  discount, setDiscount,
  setLoading
}) => {
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [file, setFile] = useState(null)
  const [fetchedImage, setFetchedImage] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const options = [

    { value: 1, label: <div><div className="badge" style={{ borderRadius: "12px 0px", backgroundColor: "#CD6113", fontWeight: "lighter" }}><FaTrophy className='pe-1' color="#F3CE58" size={20} /> Best Seller</div> Best Seller </div> },
    { value: 2, label: <div><div className="badge" style={{ color: '#FF9D33', borderRadius: "12px 2px", backgroundColor: "#FFF6DF", fontWeight: "lighter" }}><FaFireAlt className='pe-1' color="#F3CE58" size={20} /> Hot Deals</div>Hot Deals</div> },
    { value: 3, label: <div><div className="badge" style={{ borderRadius: "12px 2px", backgroundColor: "white", fontWeight: "lighter", color: '#A2A2A4' }}><img src={img} /> New</div> New</div> }
  ];

  const handleChange = (selectedOption) => {

    if (selectedOption) {
      setProductTag(selectedOption.value);
    }
    else {
      setProductTag([])
    }
  };
  const fileInputRef = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFetchedImage("")
    if (file) {
      setProductImageName(file.name);
      setProductImageSize((file.size / 1024).toFixed(2) + ' KB');
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImagePreview(reader.result);
        // setFetchedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file)
    }
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFetchedImage(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const clearSelection = () => {
    setProductImage(null);
    setProductImagePreview(null);
    setProductImageName(null);
    setProductImageName("")
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset file input value
    }
  };

  const calculateDiscount = () => {
    if (mrp > 0 && sellingPrice > 0 && mrp >= sellingPrice) {
      const discountPercentage = ((mrp - sellingPrice) / mrp) * 100;
      setDiscount(discountPercentage.toFixed(2));
    } else {
      setDiscount(null);
    }
  };
  // async function getMetaData(link) {

  //   const url = `https://api.urlmeta.org/meta?url=${link}`;

  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         'Authorization': 'Basic bmFyZXNoLmdhcmdAZ29zd2lybC5saXZlOnZqME54TUtMQ3dVajA1d3RhTzhx'
  //       },
  //       // Note: If you're running this in a secure environment (HTTPS), you should not disable SSL verification.
  //       // The following two lines are only for development purposes.
  //       // httpsAgent: new https.Agent({ rejectUnauthorized: false })
  //     });

  //     const data = response.data;
  //     const metaData = {};

  //     if (data.result.status === 'OK') {
  //       metaData.title = data.meta.title ? data.meta.title.trim() : '';
  //       metaData.description = data.meta.description ? data.meta.description.trim() : '';
  //       metaData.image = data.meta.image || '';
  //       metaData.product_price = data.meta['price:amount'] ? data.meta['price:amount'].replace(/,/g, '') : '';
  //       metaData.selling_price = data.meta['price:amount'] ? data.meta['price:amount'].replace(/,/g, '') : '';

  //       console.log({ status: 1, data: metaData });
  //     }
  //   } catch (error) {
  //     console.error('Error fetching metadata:', error);
  //   }
  // }
  const getMetaData = async (link) => {
    setLoadingOverlay(true);
    try {

      // // const formData = new FormData()
      // formData.append("productUrl", link)
      await axios.post(`https://liveshopping.asia/getProductMetadata`, {
        payload: {
          productUrl: link
        }

      }).then((res) => {
        if (res.data) {
          if (res.data.product) {
            const obj = res.data.product
            setLoading(false)
            setProductName(obj.title)
            setProductDescription(obj.description)
            setSellingPrice(obj.selling_price)
            setMrp(obj.product_price)
            setFetchedImage(obj.image)

          }
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false)
        setLoadingOverlay(false)
      })
    } catch (error) {
      console.log(error);
      setLoading(false)
    } finally {
      setLoadingOverlay(false)
    }
  }


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (productLink.length > 0) {

        getMetaData(productLink);

      }
    }, 500);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [productLink]);
  const user = useSelector(state => state.auth.user);
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!(file !== null || fetchedImage !== '')) {
      toast.warn("Please select image")
      // Your code here
    } else {
      setSubmitLoading(true)
      try {
        const formData = new FormData();
        formData.append('product_link', productLink);
        formData.append('product_title', productName);
        formData.append('product_desc', productDescription);
        formData.append('price', mrp);
        formData.append('selling_price', sellingPrice);
        formData.append('product_tag', productTag);
        formData.append('brand_product_id', brandid);
        formData.append('sku_code', productsku);
        formData.append('product_attribute', productattribute);
        if (fetchedImage.length === 0) {
          formData.append('image', file);
        } else if (fetchedImage.length > 0) {
          formData.append('product_image', fetchedImage);
        }
        // Assuming 'file' is a File object
        formData.append('brandId', user.brand_id);
        await axios.post("https://liveshopping.asia/addProduct", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          if (res.data) {
            toast.success("Suceesfully product added")

            setProductLink("")
            setProductName("")
            setProductDescription("")
            setMrp("")
            setSellingPrice("")
            setProductTag("")
            setProductImage("")
            setProductImagePreview("")
            setProductImageSize("")
            setBrandid("")
            setProductSku("")
            setDiscount("")
            setProductAttribute("")
            setFetchedImage("")
            e.target.reset()
            setSubmitLoading(false)
            setProductImageName("")
            setFile(null)
            setFetchedImage("")
          }
        }).catch((err) => {
          toast.error("Something went wrong!")
          console.log(err);
          setSubmitLoading(false)
        })
      } catch (error) {
        toast.error("Something went wrong!")
        console.log(error);
        setSubmitLoading(false)
      }
    }
  }
  return (
    <div className="container mt-3 row">
      {loadingOverlay && (
        <div className="overlay col-5">
          <div className='loader_comp_main_div'>
            <FallingLines
              color="#EEEE77"
              width="100"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          </div>
        </div>

      )
      }
      <div className='col-12'>

        <form onSubmit={(e) => handleSubmit(e)}>

          <div className='row'>
            <div className="form-group mt-2">
              <label>Product Link<sup className='text-danger'>*</sup></label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter link"
                name="productLink"
                value={productLink}
                onChange={(e) => setProductLink(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='row'>
            <div className="form-group mt-2">
              <label>Product Name<sup className='text-danger'>*</sup></label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter product name"
                name="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='row'>
            <div className="form-group mt-2">
              <label>Product Description<sup className='text-danger'>*</sup></label>
              <textarea
                className="form-control mt-2"
                placeholder="Enter product description"
                name="productDescription"
                rows={3}
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-group mt-2">
                <label>MRP<sup className='text-danger'>*</sup></label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter MRP"
                  name="mrp"
                  value={mrp}
                  onChange={(e) => setMrp(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mt-2">
                <label>Selling Price</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Enter selling price"
                  name="sellingPrice"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">

              <label className='mt-2'>Product Tag</label>

              <Select
                className='mt-2'
                value={options.find(option => option.value === productTag)}
                onChange={handleChange}
                options={options}
                isClearable={true}
                placeholder="Choose Tag"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <div className="form-group">
                <label>Product Brand id</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="product brand id"
                  name="brandid"
                  value={brandid}
                  onChange={(e) => setBrandid(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Product SKU</label>
                <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Product SKU"
                  name="productsku"
                  value={productsku}
                  onChange={(e) => setProductSku(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Product Attribute</label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="Product Attribute"
                  name="productattribute"
                  value={productattribute}
                  onChange={(e) => setProductAttribute(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <label>Product Image<sup className='text-danger'>*</sup></label>
            <div className="input-group">
              <input
                type="text"
                className="form-control mt-2"
                placeholder="No file selected"
                value={fetchedImage.length > 0 ? fetchedImage : productImageName}
                readOnly
                required
              />
              <div className="input-group-append mt-2">
                <button
                  className="btn"
                  style={{ backgroundColor: '#E8E8E8' }}
                  type="button"
                  onClick={triggerFileInput}
                >
                  Browse
                </button>
              </div>
            </div>
            {/* <input
              type="file"
              id="productImage"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleImageChange}
            /> */}
            {productImagePreview && !(fetchedImage.length > 0) && (
              <div className='card p-1 mt-2'>
                <div className='d-flex w-100'>
                  <img
                    style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                    src={productImagePreview}
                    // ref={fileInputRef}
                    alt="Product Preview"
                  />
                  <div className='d-flex flex-column ps-2 pt-1'>
                    <small className='text-muted'>{productImageName}</small>
                    <small style={{ color: "rgb(162, 162, 164)" }}>
                      {productImageSize} <b>.</b> <span className='text-success'>Uploaded</span>
                    </small>
                  </div>
                  <div className='ms-auto' onClick={clearSelection}>
                    <IoClose className='pointer_cursor' />
                  </div>
                </div>
              </div>
            )}

            {
              fetchedImage && <>
                <div>   <div className='col-5'>
                  <div className='card mt-2 p-1'>
                    <img src={fetchedImage} alt='fetched' />
                  </div>
                </div></div>
              </>}
            <div className='row mt-3 ms-1'>
              <button type='submit' className='col-2 p-2 btn btn-dark'>Upload {submitLoading ? <FaSpinner className="spinning" /> : ""}</button>
            </div>
          </div>
          <input
            type="file"
            id="productImage"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImageChange}
          />
        </form>
      </div>
    </div >
  );
};

export default ProductUrl;
