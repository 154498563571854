import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import './Profile.css'
const Companyinfo = () => {
    const [companyname, setCompanyname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [website, setWebsite] = useState('');
    const [productUrl, setProductUrl] = useState('');
    const [currency, setCurrency] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [option, setOption] = useState(false)
    const authData = useSelector((state => state.auth))
    const [logo, setLogo] = useState(authData?.user?.user_profile || "https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png");
    const [isLogoDeleted, setIsLogoDeleted] = useState(false);
    const [StoreUrl, setStoreUrl] = useState('brand.goswirl.live')
    const [location, setLocation] = useState('')
    useEffect(() => {
        // Reset file input value when logo is deleted
        if (isLogoDeleted) {
            const fileInput = document.getElementById('logoInput');
            fileInput.value = '';
        }
    }, [isLogoDeleted]);
    const handleChange = (e) => {
        setOption(e.target.value);
    };
    const handleLogoChange = (e) => {
        const selectedLogo = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            setLogo(event.target.result);
            setIsLogoDeleted(false);
        };
        reader.readAsDataURL(selectedLogo);
    };

    const handleDeleteLogo = () => {
        setIsLogoDeleted(true);
        setLogo("https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png");
    };

    return (
        <div className='container-fluid d-flex '>
            <div className='card col-6 me-1'>
                <div className='fs-18 ps-3 mt-3'>Basic information</div>
                <div className='fs-14 ps-3 pt-3'>Profile Logo</div>
                <div className='d-flex float-end text-capitalize align-items-center'>
                    <input
                        type='file'
                        accept='image/*'
                        id='logoInput'
                        style={{ display: 'none' }}
                        onChange={handleLogoChange}
                    />

                    <label htmlFor='logoInput'>
                        <img
                            src={isLogoDeleted ? "https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png" : logo}
                            alt='profile logo'
                            width={80}
                            height={80}
                            className='rounded-circle m-2 border image_profile_user'
                            style={{ cursor: 'pointer' }}
                        />
                    </label>
                    <button className='btn btn-dark ms-2' onClick={() => document.getElementById('logoInput').click()}>Upload Logo</button>
                    <button className='btn btn-light ms-2' style={{ border: "1px solid red", color: "red" }} onClick={handleDeleteLogo}>Delete</button>
                </div>
                <div className='col-12 p-3'>
                    <div className='row' style={{ overflow: "auto", height: "40vh" }}>
                        <div className="form-group mt-2">
                            <label>Company Name<sup className='text-danger'>*</sup></label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter company name"
                                name="companyname"
                                value={companyname}
                                onChange={(e) => setCompanyname(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label>Email Address<sup className='text-danger'>*</sup></label>
                            <input
                                type="email"
                                className="form-control mt-2"
                                placeholder="Enter email address"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label>Phone Number<sup className='text-danger'>*</sup></label>
                            <div className="input-group ">
                                <div className="input-group-append">
                                    <select
                                        className="form-select mt-2"
                                        value={countryCode}
                                        onChange={(e) => setCountryCode(e.target.value)}
                                    >
                                        <option value="">Country Code</option>
                                        {/* Add options for country codes */}
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Enter phone number"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />

                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <label>Website</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter website URL"
                                name="website"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label>Product UTM</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter product URL"
                                name="productUrl"
                                value={productUrl}
                                onChange={(e) => setProductUrl(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label>Currency</label>
                            <select
                                className="form-select mt-2"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                            >
                                <option value="">Select Currency</option>
                                {/* Add options for currencies */}
                            </select>
                        </div>
                        <div className="form-group mt-2">
                            <label>Store Url</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter product URL"
                                name="store url"
                                value={StoreUrl}
                                style={{ color: "#A2A2A4" }}
                                onChange={(e) => setStoreUrl(e.target.value)}
                                readOnly
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label>Location</label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter Location"
                                name="location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className=' ms-1'>
                <div className='card col-12 '>
                    <div className='fs-18 mt-3 ps-2'>Password Settings</div>
                    <div className='col-12 p-3'>
                        <div className='row'>
                            <div className="form-group mt-2">
                                <label>Old Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-2"
                                    placeholder="Enter old password"
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-2">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-2 "
                                    placeholder="Enter new password"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                            <div className='col-12'>
                                <div className="form-group mt-2">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        className="form-control mt-2"
                                        placeholder="Confirm new password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='card col-12 mt-2'>


                    <div className='col-12 p-2'>
                        <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                            <div>
                                <label className="fs-18 form-check-label ps-3" htmlFor="priceSwitch">
                                    Social simulcasting
                                    <br />
                                    <span className='fs-16 p-1'>
                                        You can link multiple social platforms to broadcast live streams.
                                    </span>
                                </label>
                            </div>
                            <div>
                                <input
                                    className="form-check-input switch-lg"
                                    type="checkbox"
                                    role="switch"
                                    id="option"
                                    value={option}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default Companyinfo;
