import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './Layout/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
// import Tour from './components/website-tour/Tour';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Productdetailpage from './components/video-settings/Productdetailpage';
import Productdetailselected from './components/video-settings/Productdetailselected';
import { useCallback, useEffect } from 'react';
import { logout } from './redux/apiSlice';
// import { initializeApp } from 'firebase/app';
// console.log = function () { };
// console.warn = function () { };
// console.error = function () { };
function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  let logoutTimer;
  const resetLogoutTimer = useCallback(() => {
    if (logoutTimer) clearTimeout(logoutTimer);
    if (loggedIn) {
      logoutTimer = setTimeout(() => {
        dispatch(logout());
      }, 600000); // Set to 60000 milliseconds for 1 minute
    }
  }, [loggedIn, dispatch]);
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (loggedIn) {
      resetLogoutTimer();

      const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];

      const handleActivity = () => resetLogoutTimer();

      events.forEach(event => window.addEventListener(event, handleActivity));
      window.addEventListener('unload', handleLogout);
      return () => {
        clearTimeout(logoutTimer);
        events.forEach(event => window.removeEventListener(event, handleActivity));
        window.removeEventListener('unload', handleLogout);
      };
    }
  }, [loggedIn, resetLogoutTimer, dispatch]);


  // window.addEventListener("unlaod", () => {
  //   if (loggedIn) {
  //     dispatch(logout());
  //   }
  // })

  return (

    <Router>
      {/* Sidebar can be placed outside the <Routes> component if it's meant to be visible across all routes */}
      {/* <Sidebar />  */}
      <ToastContainer newestOnTop />
      <Routes>
        {/* Each individual route should be wrapped inside a <Route> component */}
        {loggedIn && (
          <Route path="/" element={<Navigate to="/admin" replace />} />
        )}
        <Route path="/" element={<Login />} />
        {loggedIn && (
          <Route path="/admin/*" element={<AdminLayout />} />
        )}
        {!loggedIn && (
          <Route path="/admin/*" element={<Navigate to="/" />} />
        )}
        {/* <Route path="/admin/*" Component={(props) => <AdminLayout {...props} />} /> */}
        {/* <Route path="/dashboard" element={<DashBoard />} /> */}
        {/* Define more routes as needed */}

        {/* Navigate to login page for unknown routes */}
        <Route path="*" element={<Navigate to="/" />} />

      </Routes>
      {/* <Tour />a */}
    </Router>


  );
}

export default App;
