import React, { useRef, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import img from '../assets/images/newdeals.png'
import Select from 'react-select';
import { MdAddShoppingCart } from 'react-icons/md';
import { LuPictureInPicture } from "react-icons/lu";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FaRegComments } from "react-icons/fa6";
import { MdReviews } from "react-icons/md";
import { VscUnmute } from "react-icons/vsc";
import { FaShare, FaTrophy, FaFireAlt } from "react-icons/fa";
import { RiImageAddFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useParams, useLocation } from 'react-router-dom';
import Products from './Products';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
const ProductEdit = ({
    existingProductData, products, setProducts
}) => {
    // Initialize state variables with default values or empty strings
    const [productData, setProductData] = useState({
        productLink: '',
        productName: '',
        productDescription: '',
        mrp: '',
        sellingPrice: '100',
        productTag: '',
        productImage: null,
        productImageName: '',
        productImagePreview: null,
        productImageSize: '',
        brandid: '',
        productsku: '',
        productattribute: '',
        discount: ''
    });
    const [file, setFile] = useState(null)
    const [product, setProduct] = useState({})
    const tagOptions = [

        { value: '1', label: <div><div className="badge" style={{ borderRadius: "12px 0px", backgroundColor: "#CD6113", fontWeight: "lighter" }}><FaTrophy className='pe-1' color="#F3CE58" size={20} /> Best Seller</div> Best Seller </div> },
        { value: '2', label: <div><div className="badge" style={{ color: '#FF9D33', borderRadius: "12px 2px", backgroundColor: "#FFF6DF", fontWeight: "lighter" }}><FaFireAlt className='pe-1' color="#F3CE58" size={20} /> Hot Deals</div>Hot Deals</div> },
        { value: '3', label: <div><div className="badge" style={{ borderRadius: "12px 2px", backgroundColor: "white", fontWeight: "lighter", color: '#A2A2A4' }}><img src={img} /> New</div> New</div> }
    ];
    const fileInputRef = useRef(null);
    const location = useLocation()
    const urlarray = location.pathname.split('/')
    const product_id = atob(urlarray[urlarray.length - 1])

    const navigate = useNavigate();

    useEffect(() => {
        if (existingProductData) {
            setProductData(existingProductData);
        }
    }, [existingProductData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTagChange = (selectedOption) => {
        setProductData(prevState => ({
            ...prevState,
            productTag: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setProductData(prevState => ({
                ...prevState,
                productImageName: file.name,
                productImageSize: (file.size / 1024).toFixed(2) + ' KB'
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setProductData(prevState => ({
                    ...prevState,
                    productImagePreview: reader.result
                }));
            };
            setFile(file)
            reader.readAsDataURL(file);
        }

    };

    const triggerFileInput = () => {
        console.log(document.getElementById("asfsdfsdf"));
        fileInputRef.current?.click();
    };


    const clearSelection = () => {
        setProductData(prevState => ({
            ...prevState,
            productImage: null,
            productImagePreview: null,
            productImageName: ''
        }));
        setFile(null)

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    const calculateDiscount = () => {
        if (productData.mrp > 0 && productData.sellingPrice > 0 && productData.mrp >= productData.sellingPrice) {
            const discountPercentage = ((productData.mrp - productData.sellingPrice) / productData.mrp) * 100;
            setProductData(prevState => ({
                ...prevState,
                discount: discountPercentage.toFixed(2)
            }));
        } else {
            setProductData(prevState => ({
                ...prevState,
                discount: null
            }));
        }
    };

    useEffect(() => {
        calculateDiscount();
    }, [productData.mrp, productData.sellingPrice]);
    // setProductData({
    //     productLink: 'abv',
    //     productName: 'm,dshcj',
    //     productDescription: '',
    //     mrp: '',
    //     sellingPrice: '100',
    //     productTag: '',
    //     productImage: null,
    //     productImageName: '',
    //     productImagePreview: null,
    //     productImageSize: '',
    //     brandid: '',
    //     productsku: '',
    //     productattribute: '',
    //     discount: ''
    // })

    const user = useSelector(state => state.auth.user)
    const getSingleProductById = async () => {
        try {
            await axios.get(`https://liveshopping.asia/getProductByProductIdAndBrandId?productId=${product_id}&brandId=${user.brand_id}`).then((res) => {
                if (res.data) {
                    setProduct(res.data.product)
                    const product = res.data.product
                    setProductData({
                        productLink: product.externalLinks,
                        productName: product.productTitle,
                        productDescription: product.productDesc,
                        mrp: product.productPrice,
                        sellingPrice: product.productSellPrice,
                        productTag: product.productTags,
                        productImage: product.image,
                        productImageName: product.productImgUrl,
                        productImagePreview: product.image,
                        // productImageSize: product.externalLinks,
                        brandid: product.brandProductId,
                        productsku: product.skuCode,
                        productattribute: product.productAttr,
                    })
                }
            })
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getSingleProductById()
    }, [product_id])

    const handleUpdateProduct = async (e) => {
        e.preventDefault()
        console.log(product_id);
        try {
            const formData = new FormData();
            formData.append('product_link', productData.productLink);
            formData.append('product_title', productData.productName);
            formData.append('product_desc', productData.productDescription);
            formData.append('price', productData.mrp);
            formData.append('selling_price', productData.sellingPrice);
            formData.append('product_tag', productData.productTag);
            formData.append('brand_product_id', productData.brandid);
            formData.append('sku_code', productData.productsku);
            formData.append('product_attribute', productData.productattribute);
            formData.append('brandId', user.brand_id);
            formData.append('productId', product_id);
            formData.append('image_name', product.productImgUrl.split(".")[0]);
            if (file) {
                formData.append('image', file);
            } else {
                formData.append('product_image', product.image);
            }


            // image_name
            // p_image
            await axios.patch("https://liveshopping.asia/editProduct", formData).then((res) => {
                if (res.data) {
                    toast.success("Product updated")
                }
            }).catch((err) => {
                toast.error("Something went wrong!")
                console.log(err);
            })
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong!")
        }
    }
    return (
        <div className="container-fluid mt-3 row">
            <Helmet>
                <title>SWIRL | Product edit</title>
            </Helmet>
            <div>
                <p className='d-flex align-items-center gap-2'><BiArrowBack title='back' style={{ cursor: "pointer" }} onClick={() => navigate("/admin/products")} size={20} />Edit Product</p>
            </div>
            <div className='col-6 ps-5'>
                <form onSubmit={handleUpdateProduct}>
                    <div className='row'>
                        <div className="form-group mt-2">
                            <label>Product Link<sup className='text-danger'>*</sup></label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter link"
                                name="productLink"
                                value={productData.productLink}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="form-group mt-2">
                            <label>Product Name<sup className='text-danger'>*</sup></label>
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="Enter product name"
                                name="productName"
                                value={productData.productName}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="form-group mt-2">
                            <label>Product Description<sup className='text-danger'>*</sup></label>
                            <textarea
                                className="form-control mt-2"
                                placeholder="Enter product description"
                                name="productDescription"
                                value={productData.productDescription}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group mt-2">
                                <label>MRP<sup className='text-danger'>*</sup></label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    placeholder="Enter MRP"
                                    name="mrp"
                                    value={productData.mrp}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group mt-2">
                                <label>Selling Price</label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    placeholder="Enter selling price"
                                    name="sellingPrice"
                                    value={productData.sellingPrice}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <label className='mt-2'>Product Tag</label>
                            <Select
                                className='mt-2'
                                value={tagOptions.find(option => option.value === productData.productTag)}
                                onChange={handleTagChange}
                                options={tagOptions}
                                isClearable={true}
                                placeholder="Choose Tag"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Product Brand id</label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    placeholder="product brand id"
                                    name="brandid"
                                    value={productData.brandid}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Product SKU</label>
                                <input
                                    type="number"
                                    className="form-control mt-2"
                                    placeholder="Product SKU"
                                    name="productsku"
                                    value={productData.productsku}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Product Attribute</label>
                                <input
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Product Attribute"
                                    name="productattribute"
                                    value={productData.productattribute}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label>Product Image<sup className='text-danger'>*</sup></label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control mt-2"
                                placeholder="No file selected"
                                value={productData.productImageName}
                                readOnly
                            />
                            <div className="input-group-append mt-2">
                                <button
                                    className="btn"
                                    style={{ backgroundColor: '#E8E8E8' }}
                                    type="button"
                                    onClick={triggerFileInput}
                                >
                                    Browse
                                </button>
                            </div>
                        </div>
                        {/* <input
                            type="file"
                            id="productImage"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleImageChange}
                        /> */}
                        {productData.productImagePreview && (
                            <div className='card p-1 mt-2'>
                                <div className='d-flex w-100'>
                                    <img
                                        style={{ height: "50px", width: "35px", borderRadius: "3px" }}
                                        src={productData.productImagePreview}
                                        alt="Product Preview"
                                    />
                                    <div className='d-flex flex-column ps-2 pt-1'>
                                        <small className='text-muted'>{productData.productImageName}</small>
                                        <small style={{ color: "rgb(162, 162, 164)" }}>
                                            {productData.productImageSize} <b>.</b> <span className='text-success'>Uploaded</span>
                                        </small>
                                    </div>
                                    <div className='ms-auto ' style={{ cursor: "pointer" }} onClick={clearSelection}>
                                        <IoClose className='pointer_cursor' />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='row mt-3 ms-1'>
                            <button className='col-2 p-2 btn btn-dark'>Update</button>
                        </div>
                    </div>
                    <input
                        type="file"
                        placeholder='select'
                        id="asfsdfsdf"
                        ref={fileInputRef}
                        accept='image/*'
                        style={{ display: "none" }}
                        onChange={handleImageChange} />
                </form>
            </div>
            <div className='col-6'>
                <div className="container-fluid position-relative" style={{ backgroundColor: "#F8F8FF", width: '100%' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 pt-3">
                            <div className="card mb-4 position-relative" style={{ borderRadius: "20px" }}>
                                <video className="card-img-top" style={{ borderRadius: "20px", zIndex: "444" }} autoPlay loop muted>
                                    <source src='https://d1g3m9ml694eqp.cloudfront.net/1715162670.mp4' type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='position-absolute top-0 end-0 mt-2' style={{ marginRight: "20%" }}>
                                    <LuPictureInPicture color='white' size={22} />
                                </div>
                                <div className='position-absolute top-0 end-0 mt-2' style={{ marginRight: "10%" }}>
                                    <HiOutlineShoppingCart color='white' size={22} />
                                </div>
                                <div className='position-absolute top-0 end-0 mt-2 me-1'>
                                    <IoClose color='white' size={22} />
                                </div>
                                <div className='position-absolute end-0 d-flex flex-column align-items-end' style={{ marginRight: "5%", marginTop: "80%" }}>

                                    <VscUnmute color='white' size={22} className='mb-3' />
                                    <FaRegComments color='white' size={22} className='mb-3' />
                                    <MdReviews color='white' size={22} className='mb-3' />
                                    <FaShare color='white' size={22} className='mb-2' />
                                </div>

                                <div className='card-body position-absolute justify-content-center' style={{ borderRadius: "8px", marginBottom: "7px", background: "rgba(255, 255, 255, 0.9)", width: "100%", bottom: "0", height: "110px" }}>

                                    <div className='d-flex ps-3 pe-3' style={{ zIndex: "445", position: "relative" }}>
                                        {productData.productTag == '1' && <span className="badge position-absolute start-0 p-2 ms-3 tags1" style={{ borderRadius: "12px 12px 2px 2px", backgroundColor: "#CD6113", fontWeight: "lighter", marginTop: "-9.2%", zIndex: '446', width: "25%" }}>
                                            <FaTrophy className='pe-1' color="#F3CE58" size={15} />Best Seller
                                        </span>}
                                        {productData.productTag == '2' && <span className="badge position-absolute start-0 p-2 ms-3 tags" style={{ color: '#FF9D33', borderRadius: "12px 12px 2px 2px", backgroundColor: "#FFF6DF", fontWeight: "lighter", marginTop: "-10%", zIndex: '446', width: "25%" }}>
                                            <FaFireAlt className='pe-1' color="#F3CE58" size={20} />Hot Deals
                                        </span>}
                                        {productData.productTag == '3' &&
                                            <span className="badge position-absolute start-0 p-2 ms-3 tags" style={{ borderRadius: "12px 12px 2px 2px", backgroundColor: "white", fontWeight: "lighter", marginTop: "-10%", color: '#A2A2A4', zIndex: '446', width: "25%" }}>
                                                <img src={img} />New
                                            </span>}
                                        <div className='d-flex w-100 bg-white' style={{ borderRadius: `${productData.productTag ? "0" : "10"}px 10px 10px 10px`, overflow: "hidden" }}>
                                            <div style={{ width: "25%", overflow: "hidden" }} onClick={triggerFileInput}>
                                                {productData.productImagePreview ? (
                                                    <img
                                                        src={productData.productImagePreview}
                                                        style={{ height: "100%", width: "100%", overflow: "hidden" }}
                                                        alt='video thumbnail'
                                                    />
                                                ) : (
                                                    <div style={{ display: "grid", placeItems: "center", height: "100%" }}>

                                                        <RiImageAddFill

                                                            style={{ zIndex: "5", cursor: "pointer" }}
                                                            size={32}
                                                        />



                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ width: "75%" }}>
                                                <div className='d-flex flex-column ps-2'>
                                                    <div className='m-0 p-0' style={{ fontSize: "12px", textAlign: "right" }}>
                                                        <span className='badge text-muted p-2' style={{ backgroundColor: "#FCFCE4" }}>Rating: 4.5</span>
                                                    </div>
                                                    <span className='product_title_preview' style={{ fontSize: "14px" }}>{productData?.productName?.length === 0 ? "Product title" : productData.productName}</span>
                                                    <span style={{ fontSize: "12px", padding: "2px" }}>${productData?.sellingPrice?.length === 0 ? "SELLING " : `${productData.sellingPrice} `}<del className="text-muted">${productData?.mrp?.length === 0 ? "MRP" : productData.mrp}</del><span className='badge_discount bg-success text-white fw-light p-1 rounded ms-1'>
                                                        <small>{productData.discount ? `${productData.discount}%` : "DISCOUNT"}</small>
                                                    </span></span>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <button className='btn btn-sm btn-dark mt-2 buynow-button' style={{
                                                            fontSize: "12px", width: "75%", whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}>Buy now</button>
                                                        <MdAddShoppingCart size={35} className='pe-2 mt-1' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductEdit;
