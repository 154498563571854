import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        error: null,
        loading: false,
        loggedIn: false,
        user: null,
        videoDataForEdit: {}
    },
    reducers: {
        logout: state => {
            return {
                ...state,
                loggedIn: false,
                user: null,
                error: null
            };
        },
        loginSuccess: (state, action) => {
            return {
                ...state,
                loggedIn: true,
                user: action.payload,
                error: null
            };
        },
        loginFailure: (state, action) => {
            return {
                ...state,
                loggedIn: false,
                user: null,
                error: action.payload
            };
        },
        setLoading: (state, action) => {
            return {
                ...state,
                loading: action.payload
            };
        },
        setVideoDataForEdir: (state, action) => {
            return {
                ...state,
                videoDataForEdit: action.payload
            };
        }


    },
});

export const { loginSuccess, loginFailure, logout, setLoading, setVideoDataForEdir } = authSlice.actions;

export default authSlice.reducer;
