import React, { useState } from 'react';
import { FaCopy } from "react-icons/fa";
const AccessToken = () => {
    const [sync, setsync] = useState(false);
    const [synccode, setsynccode] = useState('tgmwb');
    const [brandId, setBrandId] = useState(false);
    const [uploadKey, setUploadKey] = useState('');
    const [customization, setCustomization] = useState('');

    const handleSyncChange = (e) => {
        setsync(e.target.checked);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className='d-flex'>
            <div className='col-12'>
                <div className='card row m-2'>
                    <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                        <div>
                            <label className="fs-18 form-check-label ps-3 p-3" htmlFor="sync">
                                Shopify Sync
                            </label>
                        </div>
                        <div>
                            <input
                                className="form-check-input switch-lg"
                                type="checkbox"
                                role="switch"
                                id="sync"
                                checked={sync}
                                onChange={handleSyncChange}
                            />
                        </div>
                    </div>
                    {sync &&
                        <div className='row ms-2'>
                            <div className="form-group mt-2">
                                <label>Code</label>
                                <div className='input-group mb-2'>
                                    <input
                                        type="text"
                                        className="form-control mt-2 "
                                        name="synccode"
                                        value={synccode}
                                        onChange={(e) => setsynccode(e.target.value)}
                                        readOnly
                                    />
                                    <button
                                        className="btn btn-outline-secondary mt-2 input-group-text"
                                        onClick={() => copyToClipboard(synccode)}
                                    >
                                        <FaCopy />
                                    </button>
                                </div>
                            </div>
                        </div>}
                </div>

                <div className='card row mt-3 m-2'>
                    <h3 className='fs-18 ms-2 mt-2'>Brand API Credentials</h3>
                    <div className="row ms-2">
                        <div>
                            <label className="fs-14 form-group mt-2" htmlFor="brandId">
                                Brand ID
                            </label>
                        </div>
                        <div>
                            <input
                                placeholder='BrandId'
                                className="form-control mt-2"
                                id="brandId"
                                checked={brandId}
                                onChange={(e) => setBrandId = (e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='row ms-2'>
                        <div className="fs-14 form-group mt-2">
                            <label >Upload Key</label>
                            <input
                                placeholder='Upload Key'
                                className="form-control mt-2"
                                name="uploadKey"
                                onChange={(e) => setUploadKey(e.target.value)}
                            />
                        </div>

                    </div>
                    <div className='row ms-2'>
                        <div className="fs-14 form-group mt-2 mb-3">
                            <label>Authorization key</label>
                            <input
                                className="form-control mt-2"
                                name="customization"
                                placeholder='Authorization Key'
                                onChange={(e) => setCustomization(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AccessToken;
