import React, { useEffect, useState, Suspense } from 'react'
import "./sidebar.css"
import logo from "../assets/images/swirl_logo.png"
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/apiSlice'
import { FallingLines } from 'react-loader-spinner'
import { Helmet } from 'react-helmet'
import { confirmAlert } from 'react-confirm-alert';
import Profile from '../pages/Profile'
import ProductEdit from '../pages/ProductEdit'
import Playlist from '../pages/PlaylistEdit'
import Table from '../pages/Table'

// Lazy loading components
const DashBoard = React.lazy(() => import('../pages/DashBoard'));
const LiveStream = React.lazy(() => import('../pages/LiveStream'));
const SSVpage = React.lazy(() => import('../pages/SSVpage'));
const VirtualShopping = React.lazy(() => import('../pages/VirtualShopping'));
const Products = React.lazy(() => import('../pages/Products'));
const VideoEditPage = React.lazy(() => import('../pages/VideoEditPage'));
const Loading = () => <>
    <Helmet>
        <title>SWIRL | Loading...</title>
    </Helmet>
    <div className='loader_comp_main_div'>
        <FallingLines
            color="#EEEE77"
            width="100"
            visible={true}
            ariaLabel="falling-circles-loading"
        />
    </div>
</>;
const Sidebar = () => {
    const [active, setActive] = useState("dashboard")
    const [tab, setTab] = useState('Reports');
    const location = useLocation()
    const navigate = useNavigate()
    const authData = useSelector((state => state.auth))
    const [isLoading, setLoading] = useState(true);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);



    const [state, setState] = useState([
        {
            startDate: new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
            endDate: yesterday,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        console.log(tab);
    }, [tab])

    useEffect(() => {
        const arr = location.pathname.split("/")
        const crrPage = arr[arr?.length - 1]

        if (arr.includes("swirl-edit")) {
            setActive("svideos")
        } else if (arr.includes("product-edit")) {
            setActive("products")
        } else {
            if (crrPage === "svideos") {
                setActive("svideos")
            } else if (crrPage === "") {
                setActive("dashboard")
            } else if (crrPage === "livestream") {
                setActive("livestream")
            } else if (crrPage === "virtual-shopping") {
                setActive("vshopping")
            } else if (crrPage === "products") {
                setActive("products")
            }
        }

    }, [location.pathname])
    const dispatch = useDispatch()
    useEffect(() => {
        if (!authData.loggedIn) {
            navigate("/")
        }
    }, [authData, navigate])
    const handleLogout = () => {
        try {
            confirmAlert({
                // title: 'Are you sure,You want to exit ?',
                message: 'Are you sure you want to exit?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            dispatch(logout())
                            navigate("/")
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });

        } catch (error) {
            console.log(error);
        }
    }
    const queryParams = new URLSearchParams(location.search);
    const Tableedit = queryParams.get('Tableedit');

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            <div className='topbar_main_div' >
                <div className="container-fluid">
                    <div className="row">

                        <div className="col">
                            <div className="topbar-content text-end">
                                <div className='d-flex float-end text-capitalize align-items-center'>
                                    {/* {active == "dashboard" && < select
                                        className="form-control me-5 position-absolute start-0"
                                        style={{ maxWidth: "200px", marginLeft: "280px" }}
                                    >
                                        <option value="">Select Brand </option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>

                                    </select>} */}
                                    <p className='text-dark mt-3'>{authData?.user?.brand_name}</p>
                                    <img
                                        src={authData?.user?.user_profile ? authData?.user?.user_profile : "https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png"}
                                        alt="hugenerd"
                                        width={40}
                                        height={40}
                                        className="rounded-circle m-2  border image_profile_user"
                                    />


                                </div>
                                {/* <span className='text-dark text-capitalizes'>{authData?.user?.brand_name}</span> */}

                            </div>
                        </div>
                    </div>

                </div >
            </div >
            <div className='sidebar_main_div' style={{ marginTop: "-60px", overflow: "hidden" }}>
                <div className="container-fluid">
                    <div className="row flex-nowrap" style={{ height: "calc(100vh)" }}>
                        <div className="col-auto col-md-3 col-xl-2  px-0 bg_yellow" >
                            <div id="sidebar" className="d-flex flex-column align-items-center align-items-sm-start px-0 pt-2 text-white min-vh-100">
                                <a
                                    href="/"
                                    className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none logo_swirl"
                                >
                                    <span >
                                        <img alt="logo" src={logo} />
                                    </span>
                                </a>
                                <a
                                    href="/"
                                    className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none logo_swirl_small d-none"
                                >
                                    <span >
                                        <img alt="logo" src="https://goswirl.shop/admin/assets/images/goswirl_favi_icon.png" />
                                    </span>
                                </a>
                                <ul
                                    className="collapse show nav flex-column w-100"
                                    id="submenu1"
                                    data-bs-parent="#menu"
                                >
                                    <li className=" w-100  p-2 ps-4 ps-4 dashboard" style={{ backgroundColor: `${active === "dashboard" ? "#17171C" : "#EEEE77"}` }} >
                                        <Link onClick={() => {
                                            setActive("dashboard")
                                            setLoading(true)
                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 500);
                                        }} to="/admin/" href="#" className="nav-link px-0 d-flex align-items-center">
                                            <div className='me-2'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_3101_222)">
                                                        <path d="M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z" fill={active === "dashboard" ? "#EEEE77" : "#17171C"} />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_3101_222">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <span className={`d-none d-sm-inline ${active === "dashboard" ? "text_yellow" : "text_black"} `}>Dashboard</span>
                                        </Link>
                                    </li>
                                    {/* <li className=" w-100  p-2 ps-4 dashboard-2" style={{ backgroundColor: `${active === "livestream" ? "#17171C" : "#EEEE77"}` }} >
                                        <Link onClick={() => {
                                            setActive("livestream")
                                            setLoading(true)
                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 500);
                                        }} to="/admin/livestream" className="nav-link px-0 d-flex align-items-center">
                                            <div className='me-2'>

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1353_856)">
                                                        <path d="M16 4C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5V9.2L22.213 5.55C22.288 5.49746 22.3759 5.4665 22.4672 5.4605C22.5586 5.4545 22.6498 5.4737 22.731 5.51599C22.8122 5.55829 22.8802 5.62206 22.9276 5.70035C22.9751 5.77865 23.0001 5.86846 23 5.96V18.04C23.0001 18.1315 22.9751 18.2214 22.9276 18.2996C22.8802 18.3779 22.8122 18.4417 22.731 18.484C22.6498 18.5263 22.5586 18.5455 22.4672 18.5395C22.3759 18.5335 22.288 18.5025 22.213 18.45L17 14.8V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H2C1.73478 20 1.48043 19.8946 1.29289 19.7071C1.10536 19.5196 1 19.2652 1 19V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H16ZM15 6H3V18H15V6ZM7.4 8.829C7.47611 8.82879 7.55069 8.8503 7.615 8.891L11.97 11.663C12.0266 11.6992 12.0731 11.749 12.1054 11.8079C12.1376 11.8668 12.1545 11.9329 12.1545 12C12.1545 12.0671 12.1376 12.1332 12.1054 12.1921C12.0731 12.251 12.0266 12.3008 11.97 12.337L7.615 15.11C7.55434 15.1487 7.48438 15.1703 7.41248 15.1725C7.34059 15.1748 7.26941 15.1576 7.20646 15.1228C7.14351 15.088 7.0911 15.0368 7.05477 14.9747C7.01844 14.9127 6.99951 14.8419 7 14.77V9.23C7 9.009 7.18 8.829 7.4 8.829ZM21 8.84L17 11.64V12.358L21 15.158V8.84Z" fill={active === "livestream" ? "#EEEE77" : "#17171C"} fillOpacity="1" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1353_856">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <span className={`d-none d-sm-inline ${active === "livestream" ? "text_yellow" : "text_black"} `}>Live Stream</span>
                                        </Link>
                                    </li> */}
                                    <li className=" w-100  p-2 ps-4 dashboard-3" style={{ backgroundColor: `${active === "svideos" ? "#17171C" : "#EEEE77"}` }} >
                                        <Link onClick={() => {
                                            setActive("svideos")
                                            setLoading(true)
                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 500);
                                        }} to="/admin/svideos" className="nav-link px-0 d-flex align-items-center">
                                            <div className='me-2'>

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1353_862)">
                                                        <path d="M3 3.993C3 3.445 3.445 3 3.993 3H20.007C20.555 3 21 3.445 21 3.993V20.007C20.9997 20.2703 20.895 20.5227 20.7089 20.7089C20.5227 20.895 20.2703 20.9997 20.007 21H3.993C3.72972 20.9997 3.4773 20.895 3.29114 20.7089C3.10497 20.5227 3.00026 20.2703 3 20.007V3.993ZM5 5V19H19V5H5ZM10.622 8.415L15.501 11.667C15.5559 11.7035 15.6009 11.753 15.632 11.8111C15.6631 11.8692 15.6794 11.9341 15.6794 12C15.6794 12.0659 15.6631 12.1308 15.632 12.1889C15.6009 12.247 15.5559 12.2965 15.501 12.333L10.621 15.585C10.5608 15.6249 10.491 15.6477 10.4189 15.6512C10.3468 15.6546 10.2751 15.6384 10.2114 15.6043C10.1477 15.5703 10.0945 15.5197 10.0573 15.4578C10.02 15.396 10.0003 15.3252 10 15.253V8.747C10.0001 8.67465 10.0199 8.60369 10.0572 8.54168C10.0944 8.47967 10.1478 8.42893 10.2116 8.39486C10.2755 8.36079 10.3473 8.34467 10.4196 8.34822C10.4919 8.35177 10.5618 8.37485 10.622 8.415Z" fill={active === "svideos" ? "#EEEE77" : "#17171C"} fillOpacity="1" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1353_862">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            <span className={`d-none d-sm-inline ${active === "svideos" ? "text_yellow" : "text_black"} `}>Shoppable Video</span>
                                        </Link>
                                    </li>
                                    {/* <li className=" w-100  p-2 ps-4 dashboard-4" style={{ backgroundColor: `${active === "vshopping" ? "#17171C" : "#EEEE77"}` }} >
                                        <Link onClick={() => {
                                            setActive("vshopping")
                                            setLoading(true)
                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 500);
                                        }} to="/admin/virtual-shopping" className="nav-link px-0 d-flex align-items-center">
                                            <div className='me-2'>

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1353_868)">
                                                        <path d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM4 5V19H20V5H4ZM12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15ZM12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17ZM17 6H19V8H17V6Z" fill={active === "vshopping" ? "#EEEE77" : "#17171C"} fillOpacity="1" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1353_868">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <span className={`d-none d-sm-inline ${active === "vshopping" ? "text_yellow" : "text_black"} `}>Virtual Shopping</span>
                                        </Link>
                                    </li>*/}
                                    {/* <li onClick={() => {
                                        setActive("products")
                                        setLoading(true)
                                        setTimeout(() => {
                                            setLoading(false)
                                        }, 500);
                                    }} className=" w-100  p-2 ps-4" style={{ backgroundColor: `${active === "products" ? "#17171C" : "#EEEE77"}` }} >
                                        <Link to="/admin/products" href="#" className="nav-link px-0 d-flex align-items-center">
                                            <div className='me-2'>

                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1353_874)">
                                                        <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM13.334 12C13.7318 12 14.1134 11.842 14.3947 11.5607C14.676 11.2794 14.834 10.8978 14.834 10.5C14.834 10.1022 14.676 9.72064 14.3947 9.43934C14.1134 9.15804 13.7318 9 13.334 9H10.5V12H13.334ZM13.334 7C14.2623 7 15.1525 7.36875 15.8089 8.02513C16.4653 8.6815 16.834 9.57174 16.834 10.5C16.834 11.4283 16.4653 12.3185 15.8089 12.9749C15.1525 13.6313 14.2623 14 13.334 14H10.5V17H8.5V7H13.334Z" fill={active === "products" ? "#EEEE77" : "#17171C"} fillOpacity="1" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1353_874">
                                                            <rect width="24" height="24" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <span className={`d-none d-sm-inline ${active === "products" ? "text_yellow" : "text_black"} `}>Products</span>
                                        </Link>
                                    </li> */}
                                </ul>
                                <div className='mt-auto text-black w-100'>
                                    <ul className="collapse show nav flex-column w-100"
                                        id="submenu1"
                                        data-bs-parent="#menu">
                                        {/* <li className=" w-100  p-2 ps-4" style={{ backgroundColor: "#EEEE77" }} >
                                            <Link to="/" className="nav-link px-0 d-flex align-items-center">
                                                <div className='me-2'>

                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_1353_881)">
                                                            <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM13 13.355V14H11V12.5C11 12.2348 11.1054 11.9804 11.2929 11.7929C11.4804 11.6054 11.7348 11.5 12 11.5C12.2841 11.5 12.5623 11.4193 12.8023 11.2673C13.0423 11.1154 13.2343 10.8984 13.3558 10.6416C13.4773 10.3848 13.5234 10.0988 13.4887 9.81684C13.454 9.53489 13.34 9.26858 13.1598 9.04891C12.9797 8.82924 12.7409 8.66523 12.4712 8.57597C12.2015 8.48671 11.912 8.47587 11.6364 8.54471C11.3608 8.61354 11.1104 8.75923 10.9144 8.96482C10.7183 9.1704 10.5847 9.42743 10.529 9.706L8.567 9.313C8.68863 8.70508 8.96951 8.14037 9.38092 7.67659C9.79233 7.2128 10.3195 6.86658 10.9086 6.67332C11.4977 6.48006 12.1275 6.44669 12.7337 6.57661C13.3399 6.70654 13.9007 6.99511 14.3588 7.41282C14.8169 7.83054 15.1559 8.36241 15.3411 8.95406C15.5263 9.54572 15.5511 10.1759 15.4129 10.7803C15.2747 11.3847 14.9785 11.9415 14.5545 12.3939C14.1306 12.8462 13.5941 13.1779 13 13.355Z" fill="#17171C" fillOpacity="1" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1353_881">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </div>
                                                <span className={`d-none d-sm-inline text_black`}>help</span>
                                            </Link>
                                        </li> */}
                                        {/* <li className=" w-100  p-2 ps-4" >
                                            <Link to="/admin/profile"
                                                onClick={() => {
                                                    setActive("profile")
                                                    setLoading(true)
                                                    setTimeout(() => {
                                                        setLoading(false)
                                                    }, 500);
                                                }} className="nav-link px-0 d-flex align-items-center">
                                                <div className='me-2'>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: location.pathname === '/admin/profile' ? '#17171C' : '#EEEE77' }}>
                                                        <path d="M12 14V16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM14.595 18.812C14.4682 18.2784 14.4682 17.7226 14.595 17.189L13.603 16.616L14.603 14.884L15.595 15.457C15.9932 15.0799 16.4745 14.8017 17 14.645V13.5H19V14.645C19.532 14.803 20.012 15.085 20.405 15.457L21.397 14.884L22.397 16.616L21.405 17.189C21.5316 17.7222 21.5316 18.2778 21.405 18.811L22.397 19.384L21.397 21.116L20.405 20.543C20.0068 20.9201 19.5255 21.1983 19 21.355V22.5H17V21.355C16.4745 21.1983 15.9932 20.9201 15.595 20.543L14.603 21.116L13.603 19.384L14.595 18.812ZM18 19.5C18.3978 19.5 18.7794 19.342 19.0607 19.0607C19.342 18.7794 19.5 18.3978 19.5 18C19.5 17.6022 19.342 17.2206 19.0607 16.9393C18.7794 16.658 18.3978 16.5 18 16.5C17.6022 16.5 17.2206 16.658 16.9393 16.9393C16.658 17.2206 16.5 17.6022 16.5 18C16.5 18.3978 16.658 18.7794 16.9393 19.0607C17.2206 19.342 17.6022 19.5 18 19.5Z" fill="#17171C" fillOpacity="1" />
                                                    </svg>
                                                </div>
                                                <span className={`d-none d-sm-inline`} >Profile</span>
                                            </Link>
                                        </li> */}
                                        <li className=" w-100  p-2 ps-4" style={{ backgroundColor: "#EEEE77", cursor: "pointer" }} >

                                            <div onClick={() => handleLogout()} className="nav-link px-0 d-flex align-items-center">
                                                <div className='me-2'>
                                                    <svg width="19" height="20" viewBox="0 0 19 20" fill="#17171C" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z" fill="#17171C" fillOpacity="1" />
                                                    </svg>
                                                </div>
                                                <span className={`d-none d-sm-inline text_black`}>Sign out</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col py-3" style={{ marginTop: "60px", height: "calc(98vh - 60px)", overflow: tab == 'Reports' || window.location.href.includes("/products") || Tableedit == true ? "auto hidden" : "" }}>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <DashBoard state={state} tab={tab} setTab={setTab} setState={setState} />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/livestream"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <LiveStream />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/svideos"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <SSVpage state={state} tab={tab} setTab={setTab} setState={setState} />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/virtual-shopping"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <VirtualShopping />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/products"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <Products />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/profile"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <Profile />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/swirl-edit/:id"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <VideoEditPage />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/product-edit/:id"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <ProductEdit />}
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path="/comment/:id"
                                    element={
                                        <Suspense fallback={isLoading ? <Loading /> : null}>
                                            {isLoading ? <Loading /> : <Table />}
                                        </Suspense>
                                    }
                                />

                                <Route path="*" element={<Navigate to="/admin" />} />
                            </Routes>
                        </div>
                        {/* <div style={{ position: "absolute", zIndex: "10000", backgroundColor: "red" }}>
                                <h1>loading...</h1>
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar