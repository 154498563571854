import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from './redux/authApi';
import authReducer from './redux/apiSlice';
import dateReducer from './redux/dateSlice'; // Import the new reducer

// Configuration for redux-persist
const persistConfig = {
    key: 'root',
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        date: dateReducer, // Add the new reducer here
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ["loginSuccess.type, loginFailure.type, logout.type, setLoading.type"],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['auth.error', 'date.startDate', 'date.endDate'],
            },
        }).concat(authApi.middleware),
});

const persistor = persistStore(store);

export { store, persistor };
