import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startDate: null,
    endDate: null
};

const dateSlice = createSlice({
    name: 'date',
    initialState,
    reducers: {
        FilterDate: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
    },
});

export const { FilterDate } = dateSlice.actions; // Corrected export statement
export default dateSlice.reducer;
