import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import ModalContactSwirl from './Modal'; // Import the Modal component

const Profileproducts = () => {
    const [Shoppable, SetShoppable] = useState(false);
    const [code] = useState('abc');
    const [livestream, setlivestream] = useState(false);
    const [code2] = useState('ABC');
    const [superadmin, setSuperadmin] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
        SetShoppable(e.target.checked);
    };

    const handleChange2 = (e) => {
        setlivestream(e.target.checked);
    };

    const handleChangeadmin = (e) => {
        setSuperadmin(e.target.checked);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmModal = () => {
        setShowModal(false);
        // Perform your sub domain creation logic here
        console.log('Sub Domain Created');
    };
    const isVerified = false

    return (
        <div>
            <div className='card row mb-3 m-1'>
                <div className='col-12 p-3'>
                    <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                        <div>
                            <label className="fs-18 form-check-label ps-3" htmlFor="Shoppable">
                                Shoppable Video
                            </label>
                        </div>
                        <div>
                            <input
                                className="form-check-input switch-lg"
                                type="checkbox"
                                role="switch"
                                id="Shoppable"
                                checked={Shoppable}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {Shoppable &&
                        <div className='row ms-2'>
                            <div className="form-group mt-2">
                                <div>
                                    <label className='me-2'>Code</label>
                                </div>
                                <div className='input-group'>
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        name="code"
                                        value={code}
                                        readOnly
                                    />
                                    <button
                                        className="btn btn-outline-secondary mt-2 input-group-text"
                                        onClick={() => copyToClipboard(code)}
                                    >
                                        <FaCopy />
                                    </button>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>

            <div className='card row m-1'>
                <div className='col-12 p-3'>
                    <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0">
                        <div>
                            <label className="fs-18 form-check-label ps-3" htmlFor="livestream">
                                LiveStream
                            </label>
                        </div>
                        <div>
                            <input
                                className="form-check-input switch-lg"
                                type="checkbox"
                                role="switch"
                                id="livestream"
                                checked={livestream}
                                onChange={handleChange2}
                            />
                        </div>
                    </div>
                    {livestream &&
                        <div className='row ms-2'>
                            <div className="form-group mt-2">
                                <label className='me-2'>Code</label>
                                <div className='input-group'>
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        name="code2"
                                        value={code2}
                                        readOnly
                                    />
                                    <button
                                        className="btn btn-outline-secondary mt-2 input-group-text"
                                        onClick={() => copyToClipboard(code2)}
                                    >
                                        <FaCopy />
                                    </button>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div className='card row mt-3 m-1 p-2'>
                <div className="form-check form-switch d-flex justify-content-between align-items-center pt-2 ps-0" onClick={() => {
                    if (!isVerified) {
                        setShowModal(true)
                    }
                }}>
                    <div>
                        <label className="fs-18 form-check-label ps-3" htmlFor="superadmin">
                            Super admin
                            <br />
                            <div className='fs-14'>
                                You can create sub-admin roles to delegate important tasks.
                            </div>
                        </label>
                    </div>
                    <div>
                        <input
                            className="form-check-input switch-lg"
                            type="checkbox"
                            role="switch"
                            id="superadmin"
                            checked={superadmin}
                            onChange={handleChangeadmin}
                            disabled={!isVerified}
                        />
                    </div>
                </div>
                {superadmin && isVerified &&
                    <div>
                        <button className='btn btn-dark mt-2 mb-2' onClick={handleButtonClick}>
                            Create sub Domain
                        </button>
                    </div>
                }
            </div>
            <ModalContactSwirl show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmModal} />
        </div>
    );
}

export default Profileproducts;
