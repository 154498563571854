import React, { useState, useRef } from 'react'
import { RiFileExcel2Fill } from "react-icons/ri";
import Upload from './Upload';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
const Bulk = () => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loading, setLoading] = useState(false)
    const [previewModel, setPreviewModel] = useState(false)
    const [loadingPercent, setLoadingPercent] = useState(0)
    const [csvData, setCsvData] = useState([])

    const handleDownload = () => {

        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/sample.csv`;
        link.download = 'sample.csv';
        link.click();
    };
    const clearPreview = () => {
        setPreviewUrl(null);
        setCsvData(null)
    };
    const user = useSelector(state => state.auth.user);
    const handleSubMit = async () => {
        setLoading(true)
        try {
            if (!previewUrl) {
                toast.error("Please choose csv file")
            } else {
                const formData = new FormData()
                formData.append("csvData", previewUrl)
                formData.append("brandId", user.brand_id)
                formData.append("currencySymbols", user.currencySymbols)
                await axios.post("https://liveshopping.asia/uploadBulkProducts", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if (res.data) {
                        toast.success("Bulk product upload success")
                        setLoading(false)
                        setPreviewUrl(null)

                    }
                }).catch((err) => {
                    setLoading(false)
                    console.log(err);
                    toast.error("Something went wrong")
                })
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error("Something went wrong")
        }

    }

    return (
        <div>
            <div>
                <Upload setCsvData={setCsvData} loadingPercent={loadingPercent} setPreviewUrl={setPreviewUrl} previewUrl={previewUrl} clearPreview={clearPreview} />
            </div>
            <div className='row mt-5'>
                <span style={{ fontSize: "16px" }} >
                    Download Product Sample File
                    <span className='ms-4' onClick={handleDownload} style={{ color: "#334499", cursor: 'pointer' }}><RiFileExcel2Fill size={28} color='#334499' onClick={handleDownload} style={{ verticalAlign: "bottom" }} /> Sample File </span>
                </span>

            </div>
            <div>
                <button className='btn btn-dark mt-4' onClick={handleSubMit}> Upload {loading ? <FaSpinner className="spinning" /> : ""}</button>
            </div>
        </div>
    )
}

export default Bulk